import React from 'react'
import { Link } from 'gatsby'

const EventSidebar = ({ categories, date, onInputChange, years }) => {
    
    return (
        <div className="col-lg-4 col-md-12">
            <div className='contact-sidebar'>
                <div className='contact-sidebar-info'>
                    <h5>Seleccione una fecha</h5>
                    <div className="row mb-3">
                        <div className="col-7">
                            <select className="form-control" name="month" id="month" defaultValue={date.month} onChange={e => onInputChange(e)}>
                                <option value="all">Todos los meses</option>
                                <option value="1">Enero</option>
                                <option value="2">Febrero</option>
                                <option value="3">Marzo</option>
                                <option value="4">Abril</option>
                                <option value="5">Mayo</option>
                                <option value="6">Junio</option>
                                <option value="7">Julio</option>
                                <option value="8">Agosto</option>
                                <option value="9">Septiembre</option>
                                <option value="10">Octubre</option>
                                <option value="11">Noviembre</option>
                                <option value="12">Diciembre</option>
                            </select>
                        </div>
                        <div className="col-5">
                            <select className="form-control" name="year" id="year" defaultValue={date.year} onChange={e => onInputChange(e)} >
                                {years.length > 0 && years.map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <h5>Categorías</h5>
                    <ul className="mt-4">
                        {categories.length > 0 && categories.map(category => (
                            <li key={category.id}>
                                <Link to={`/events?category=${category.slug}`}>
                                    {category.name}
                                </Link>
                            </li>
                        ))}
                        <li>
                            <Link to={`/events`}>
                                Todas
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default EventSidebar