import React from 'react'
import { Link } from 'gatsby'

const EventCard = ({ event, year }) => {
    const media = event.media.filter(media => media.collection_name === 'featured_images')

    const formatDate = (date) => {
        const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        let newDate = new Date(date);
        let dateMonth = months[newDate.getMonth()];
        let dateDay = newDate.getDate();
        let dateString = dateDay + ' ' + dateMonth;
        return dateString;
    }

    return (
        <li className='timeline-block'>
            <Link to={`/blog-details?slug=${event.slug}`}>
                <div className='timeline-date'>
                    <span>
                        {formatDate(event.start_date) === formatDate(event.end_date) ?
                            formatDate(event.start_date)
                            :
                            <>
                                De {formatDate(event.start_date)}<br />
                                a {formatDate(event.end_date)}
                            </>
                        }
                    </span>
                    <small>{year}</small>
                </div>

                <div className='timeline-icon'>
                    <span className='dot-badge'></span>
                </div>

                <div className='timeline-content'>
                    <div className='row align-items-center'>
                        <div className='col-lg-7 col-md-12'>
                            <div className='content'>
                                <small>
                                    {event.categories.map((category, index) => {
                                        return (
                                            index === 0 ? category.name : ', ' + category.name
                                        )
                                    })}
                                </small>
                                <h3>{event.title}</h3>
                                <p>{event.summary}</p>
                            </div>
                        </div>

                        <div className='col-lg-5 col-md-12'>
                            <div className='image'>
                                {Object.keys(media).length > 0 ?
                                    <img src={media[0].original_url} alt={event.title} />
                                    :
                                    <img src='/static/blog-img4-752cc0c9b612ab052869540a2cf07c08.jpg' alt={event.title} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </li>
    )
}

export default EventCard