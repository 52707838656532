import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import { getEvents } from '../api/event'
import { navigate } from 'gatsby'
import EventsContent from '../components/Events/EventsContent'
import { getTime } from '../utils/get-time'
import { getSettings } from '../api/settings'
const Events = ({ location }) => {
    const now = new Date();

    const [events, setEvents] = useState()
    const [categories, setCategories] = useState()
    const [date, setDate] = useState({
        month: 'all',
        year: now.getFullYear()
    })
    const [settings, setSettings] = useState(null)

    const params = new URLSearchParams(location.search)
    const category = params.get('category')

    const onInputChange = e => {
        setDate({ ...date, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        (async () => {
           
            if (getTime() > 5 || (!localStorage.getItem('settings') || localStorage.getItem('settings')=== '' || localStorage.getItem('settings') === '[]')) {
                try {
                    setSettings(null)
                    const data = await getSettings()
                    localStorage.setItem('settings', JSON.stringify(data))
                    setTimeout(() => {
                        localStorage.setItem('time', new Date().getTime())
                      }, "15000");
                    setSettings(data)
                } catch (error) {
                    setSettings(null)
                }
               
            } else {
              
                setSettings(JSON.parse(localStorage.getItem('settings')))
            }
        })()
    }, [])
    useEffect(() => {
        (async () => {
            try {
                setCategories(null)
                setEvents(null)
                const data = await getEvents(category, date)
                setCategories(data.categories)
                setEvents(data.events)
            } catch (error) {
                setCategories(null)
                setEvents(null)
                navigate('/404')
            }
        })()
    }, [date, category])

    const getYears = () => {
        let array = [];
        for (let i = now.getFullYear() + 3; i >= 2015; i--) {
            array.push(i);
        }
        return array;
    }

    const years = getYears();

    if (!events) return null

    return (
        <Layout title={settings?.find(x => x.key === 'page.events.title') ? settings.find(x => x.key === 'page.events.title').value : 'Agenta de Eventos'}>
            <PageBanner
                pageTitle={settings?.find(x => x.key === 'page.events.title') ? settings.find(x => x.key === 'page.events.title').value : 'Agenta de Eventos'}
                homePageText='Inicio'
                homePageUrl='/'
                activePageText={settings?.find(x => x.key === 'page.events.title') ? settings.find(x => x.key === 'page.events.title').value : 'Agenta de Eventos'}
            />
            <EventsContent
                date={date}
                categories={categories}
                events={events}
                onInputChange={onInputChange}
                years={years}
            />
        </Layout>
    )
}

export default Events
