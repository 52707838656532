import React from 'react'
import EventCard from './EventCard'
import EventSidebar from './EventSidebar'

const EventsContent = ({ date, categories, events, onInputChange, years }) => {

    return (
        <section className="history-area ptb-100 bg-fafafb">
            <div className="container">
                <div className='row'>
                    <div className="col-lg-8 col-md-12">
                        {events.length > 0 ?
                            <ol className="timeline history-timeline mb-4">
                                {events.map((event) => {
                                    return (
                                        <EventCard
                                            key={event.id}
                                            event={event}
                                            year={date.year}
                                        />
                                    )
                                })}
                            </ol>
                            :
                            <div className="text-center mb-4">
                                <h5>No hay eventos disponibles</h5>
                            </div>
                        }
                    </div>
                    <EventSidebar
                        categories={categories}
                        date={date}
                        onInputChange={onInputChange}
                        years={years}
                    />
                </div>
            </div>
        </section>
    )
}

export default EventsContent